import "../styles/Footer.scss";

import { Link } from "gatsby";
import React from "react";

function Footer(props) {
  return (
    <div className="footer-container">
      <div className="footer-item">
        <Link to="/mentions-legales" className="footer-link">
          Mentions légales et conditions de vente
        </Link>
      </div>
      <div className="footer-item credits">
        <span>BON ENTENDEUR © {new Date().getFullYear()}</span>
      </div>
      <div className="footer-item">Tous droits réservés.</div>
    </div>
  );
}

export default Footer;
