import "../styles/Shop.scss";

import React from "react";
import { motion } from "framer-motion";

import ProductsList from "./ProductsList.jsx";
// import ShopFilters from "./ShopFilters.jsx";

function Shop({ allProducts, scrollYPosition }) {
  const categories = [
    "albums",
    "mixtapes",
    "vêtements",
    "accessoires",
    "autres",
  ];
  // const [filterCat, setFilterCat] = useState(categories);
  // const [filterSearch, setFilterSearch] = useState("");
  const filterCat = categories;
  const filterSearch = "";

  return (
    <motion.div
      className="shop-container"
      style={{
        transform: `translateY(${scrollYPosition * 0.2}px`,
      }}
    >
      {/* <ShopFilters
        categories={categories}
        filterCat={filterCat}
        setFilterCat={(e) => {
          setFilterCat(e);
        }}
        filterSearch={filterSearch}
        setFilterSearch={(e) => setFilterSearch(e)}
      /> */}
      <ProductsList
        allProducts={allProducts}
        filterCat={filterCat}
        filterSearch={filterSearch}
      />
    </motion.div>
  );
}

export default Shop;
