import "../styles/ProductList.scss";

// import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

import Product from "./Product.jsx";
import Socials from "./Socials.jsx";
import { window } from "browser-monads";

// import ProductsContext from "../context/ProductsContext.jsx";

function ProductsList({ allProducts, filterCat, filterSearch, isMobile }) {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [listOfFilteredProducts, setListOfFilteredProducts] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
  }, []);

  //GARDE UNIQUEMENT LES PRODUITS CONTENTFUL DONT LA CATÉGORIE EST DANS LE FILTRE
  useEffect(() => {
    let tempFilteredProducts = [];

    if (allProducts.length > 0) {
      allProducts.forEach((back_product) => {
        let isProductCatInFilterCat = back_product.categories.some((r) => {
          if (filterCat.includes(r.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
        let isProductSearched = back_product.titre
          .toLowerCase()
          .includes(filterSearch.toLowerCase());
        if (isProductCatInFilterCat && isProductSearched) {
          tempFilteredProducts.push(back_product);
        }
      });
      setFilteredProducts(tempFilteredProducts);
    }
  }, [allProducts, filterCat, filterSearch]);

  //MAP LES PRODUITS FILTRÉS ET RENDER LA LISTE DES PRODUITS
  useEffect(() => {
    let tempListofProducts = filteredProducts.map((product, i) => {
      return (
        <div className="product-container" key={i}>
          <Product
            titre={product.titre}
            sousTitre={product.sousTitre}
            bigwaxId={product.bigwaxId}
            prix={product.prix}
            images={product.images}
            index={i}
          />
        </div>
      );
    });
    setListOfFilteredProducts(tempListofProducts);
  }, [filteredProducts]);

  return (
    <div className="productList-container">
      <Socials />
      <h2 className="productList-title">
        <div className="productList-separator"></div>
        SHOP
        {((listOfFilteredProducts.length > 4 && windowWidth <= 1200) ||
          listOfFilteredProducts.length > 6) && (
          <>
            <div className="scrollDown1" />
            <div className="scrollDown2" />
          </>
        )}
      </h2>
      <div className="productList-inner">
        {/* <AnimatePresence>{listOfFilteredProducts}</AnimatePresence> */}
        {listOfFilteredProducts}
      </div>
    </div>
  );
}

export default ProductsList;
