import '../styles/index.scss';

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import Div100vh from 'react-div-100vh';
import Footer from '../components/Footer.jsx';
import Helmet from 'react-helmet';
import Logo from '../components/Logo.jsx';
import Shop from '../components/Shop.jsx';
import News from '../components/News.jsx';
import { graphql } from 'gatsby';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

var base64 = require('base-64');

// markup
const IndexPage = ({ data }) => {
  const [allProducts, setAllProducts] = useState([]);
  const news_data = data.allContentfulPageShop.edges[0].node;
  const [scrollYPosition, setScrollYPosition] = useState(0);

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollYPosition(currPos.y);
  });

  useEffect(() => {
    let produits_contentful_array =
      data.allContentfulPageShop.edges[0].node.shopProductsList;

    let produits_contentful_valid_array = produits_contentful_array.filter(
      (contentful_product) => {
        let id_b64 = base64.encode(
          'gid://shopify/Product/' + contentful_product.bigwaxId
        );
        let id = 'gid://shopify/Product/' + contentful_product.bigwaxId;

        let is_contentful_product_valid =
          data.allShopifyProduct.edges.filter((shopify_product) => {
            // return shopify_product.node.shopifyId === id_b64;
            return shopify_product.node.shopifyId === id;
          }).length > 0;

        return is_contentful_product_valid;
      }
    );

    // console.log('all shopify: ', data.allShopifyProduct.edges);
    // console.log({ produits_contentful_array });
    // console.log({ produits_contentful_valid_array });

    const results = produits_contentful_valid_array.map(
      (valid_contentful_product) => {
        let id_b64 = base64.encode(
          'gid://shopify/Product/' + valid_contentful_product.bigwaxId
        );
        let id = 'gid://shopify/Product/' + valid_contentful_product.bigwaxId;
        let current_shopify_product = data.allShopifyProduct.edges.filter(
          (shopify_product) => {
            // return shopify_product.node.shopifyId === id_b64;
            return shopify_product.node.shopifyId === id;
          }
        );
        console.log({ current_shopify_product });
        return {
          titre: valid_contentful_product.titre,
          sousTitre: valid_contentful_product.sousTitre,
          id_bigwax: valid_contentful_product.bigwaxId,
          id_b64: id_b64,
          categories: valid_contentful_product.categories,
          prix: current_shopify_product[0].node.variants[0].price,
          // instock: current_shopify_product[0].node.availableForSale,
          images: current_shopify_product[0].node.images.map(
            (image) => image.originalSrc
          ),
        };
      }
    );

    setAllProducts(results);
  }, [data]);

  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      className='main'
    >
      <Helmet title='Bon Entendeur | Shop' htmlAttributes={{ lang: 'fr' }}>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
        />
        <meta
          name='description'
          content='Bon Entendeur - Boutique officielle'
        />
      </Helmet>
      {/* MOBILE */}
      <div
        className='index-mobile'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Logo />
        <News data={news_data} scrollYPosition={scrollYPosition} />
        <Shop allProducts={allProducts} scrollYPosition={scrollYPosition} />
        <Footer />
      </div>
      {/* /MOBILE */}
      {/* DESKTOP */}
      <Div100vh
        className='index-desktop'
        style={{
          width: '100%',
          maxWidth: '1400px',
          // overflow: "hidden",
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: 'calc(100% - 62px)',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              minWidth: '340px',
              width: '45%',
              height: '100%',
              // maxWidth: "400px",
            }}
          >
            <Logo />
            <Shop allProducts={allProducts} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '65%',
              height: '100%',
            }}
          >
            <News data={news_data} />
          </div>
        </div>
        <Footer />
      </Div100vh>
      {/* /DESKTOP */}
    </motion.div>
  );
};

export const query = graphql`
  query InfoQuery {
    allShopifyProduct(filter: { vendor: { eq: "BON ENTENDEUR" } }) {
      edges {
        node {
          variants {
            price
          }
          title
          shopifyId
          images {
            originalSrc
          }
        }
      }
    }
    allContentfulPageShop {
      edges {
        node {
          newsDescription
          newsImage {
            file {
              url
            }
          }
          newsTitle
          newsCta
          newsCtaLink
          shopTitle
          shopProductsList {
            bigwaxId
            categories
            titre
            sousTitre
          }
        }
      }
    }
  }
`;

export default IndexPage;
