import "../styles/Socials.scss";

import React, { useContext } from "react";

import LogoReseauxContext from "../context/LogoReseauxContext.jsx";
import icon_fb from "../images/icon2_fb.svg";
import icon_home from "../images/icon2_home.svg";
import icon_insta from "../images/icon2_insta.svg";
import icon_youtube from "../images/icon2_youtube.svg";

function Socials(props) {
  const LogoReseauxData = useContext(LogoReseauxContext);

  return (
    <div className="socials-container">
      <div className="rb-socials-container">
        <a
          href={LogoReseauxData.rs_home}
          target="_blank"
          rel="noopener noreferrer"
          className="rb-socials-item"
        >
          <img id="home-logo" alt="home" src={icon_home} />
        </a>
        <a
          href={LogoReseauxData.rs_instagram}
          target="_blank"
          rel="noopener noreferrer"
          className="rb-socials-item"
        >
          <img id="instagram-logo" alt="instagram" src={icon_insta} />
        </a>
        <a
          href={LogoReseauxData.rs_facebook}
          target="_blank"
          rel="noopener noreferrer"
          className="rb-socials-item"
        >
          <img id="facebook-logo" alt="facebook" src={icon_fb} />
        </a>
        <a
          href={LogoReseauxData.rs_youtube}
          target="_blank"
          rel="noopener noreferrer"
          className="rb-socials-item"
        >
          <img id="youtube-logo" alt="youtube" src={icon_youtube} />
        </a>
      </div>
    </div>
  );
}

export default Socials;
