import "../styles/News.scss";

import React, { useEffect, useState } from "react";

import Socials from "./Socials.jsx";
import { motion } from "framer-motion";

function News({ data, scrollYPosition }) {
  const ref = React.useRef();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const setMouseOffsetElement = (mouse, elementCoord, elementSize) => {
    let offset = (mouse - elementCoord - elementSize / 2) / (elementSize / 2);
    if (mouse < elementCoord) {
      offset = 0;
    }
    return offset;
  };

  const onMouseMove = (event) => {
    let videoX = ref.current.getBoundingClientRect().x;
    let videoWidth = ref.current.getBoundingClientRect().width;
    let videoY = ref.current.getBoundingClientRect().y;
    let videoHeight = ref.current.getBoundingClientRect().height;
    let x = setMouseOffsetElement(event.x, videoX, videoWidth);
    let y = setMouseOffsetElement(event.y, videoY, videoHeight);
    setMousePosition({ x, y });
    setMousePosition({ x, y });
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  });

  return (
    <div className="news-container">
      <motion.div
        className="news-social-container"
        style={{
          transform: `translateY(${-scrollYPosition * 0.3}px`,
        }}
      >
        <Socials />
      </motion.div>
      <motion.div
        className="news-header"
        style={{
          transform: `translateY(${-scrollYPosition * 0.3}px`,
        }}
      >
        <div className="news-titles">
          <h1 className="news-title">{data.newsTitle}</h1>
          <h2 className="news-description">{data.newsDescription}</h2>
        </div>
        <a
          className="news-CTA-container"
          href={data.newsCtaLink}
          target="_blank"
          rel="noreferrer"
        >
          <button className="news-CTA">ÉCOUTER</button>
        </a>
      </motion.div>
      <div ref={ref} className="news-content">
        <video
          style={{
            transform: `rotateX(${-mousePosition.y * 5}deg) rotateY(${
              mousePosition.x * 5
            }deg)`,
            transition: "transform 0.8s ease-out",
          }}
          autoPlay={true}
          playsInline
          muted={true}
          loop={true}
          controls={false}
          id="home-logo"
          alt="home"
        >
          <source src={data.newsImage.file.url} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default News;
